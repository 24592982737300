<template>
  <div class="article-grid">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <CategoryTitle :category="category" :count="count" />
      <div class="mx-n2" v-if="mergedFilterData.length > 0">
        <v-chip-group
          active-class="active-chip"
          class="my-2"
          v-model="selectedClass"
        >
          <v-chip @click="handleFilterChangeChip(null)" :value="null"
            >Tutti</v-chip
          >
          <v-chip
            v-for="tag in mergedFilterData"
            :key="tag.id"
            @click="handleFilterChangeChip(tag.id)"
            :value="tag.id"
            :class="{ 'active-chip': tag.id == selectedClass }"
          >
            {{ tag.description }}
          </v-chip>
        </v-chip-group>
      </div>
      <v-row
        ><v-col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          v-for="article in articleList"
          :key="article.id"
        >
          <ArticleCard :article="article" /> </v-col
      ></v-row>
      <v-btn
        v-if="hasMoreItems()"
        block
        text
        large
        depressed
        rounded
        color="primary"
        @click="loadMore()"
        v-intersect="onIntersect"
        class="mt-3"
        :loading="loading"
        >{{ $t("recipes.showMore") }}</v-btn
      >
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.article-grid {
  .v-chip:not(.v-chip--active) {
    background-color: var(--v-grey-lighten1) !important;
    border-color: var(--v-grey-lighten1) !important;
  }
  .v-chip-group .v-chip:first-child {
    margin-left: 8px;
  }
  .active-chip {
    background-color: $primary !important;
    border-color: $primary !important;
    color: white;
  }
}
</style>
<script>
import ArticleCard from "@/components/article/ArticleCard.vue";

import CategoryTitle from "@/components/category/CategoryTitle.vue";

import cmService from "~/service/mksCmService";
import categoryMixins from "~/mixins/category";

export default {
  components: {
    CategoryTitle,
    ArticleCard
  },
  name: "ArticleClassGrid",
  mixins: [categoryMixins],
  data() {
    return {
      articleList: [],
      articleClasses: [],
      selectedClass: null,
      pager: null,
      page: 0,
      count: 0,
      mergedFilterData: [],
      pageSize: 24,
      loading: false
    };
  },
  computed: {},
  methods: {
    async reload() {
      // let parentArticleTypeId = this.$ebsn.meta(
      //   this.category,
      //   "template_model.ARTICLE_TYPE"
      // );
      let params = {
        parent_article_type_id: [1, 2],
        page_size: this.pageSize
      };

      if (this.$route.query["article_class_id"]) {
        params["article_class_id"] = this.$route.query["article_class_id"];
      }
      if (this.$route.query.page) {
        params["page"] = this.$route.query.page;
      }
      if (params["article_class_id"]) {
        this.selectedClass = parseInt(params["article_class_id"]);
      } else {
        this.selectedClass = null;
      }
      params["sort"] = "pub_date_des";
      let content = await cmService.searchArticle(params);
      this.articleList = content?.data?.articles;
      this.pager = content?.data?.page;
      this.articleClasses = content?.data?.facets;
      this.key++;
    },
    handlePageChange(page) {
      // let queryParam = Object.assign(this.$route.query, { page: page });
      // this.$router.push({
      //   path: this.$route.path,
      //   query: queryParam
      // });
      const query = { ...this.$route.query, page: page };
      this.$router.push({ query });
    },
    async loadMore() {
      if (this.hasMoreItems()) {
        this.loading = true;
        let params = {
          parent_article_type_id: [1, 2],
          page: this.pager.selPage + 1,
          page_size: this.pageSize
        };
        if (this.$route.query["article_class_id"]) {
          params["article_class_id"] = this.$route.query["article_class_id"];
        }
        params["sort"] = "pub_date_des";
        let content = await cmService.searchArticle(params);
        this.loading = false;
        this.pager = content?.data?.page;
        this.articleList.push(...content?.data?.articles);
      }
    },
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    handleFilterChange(filter) {
      const query = {
        ...this.$route.query,
        article_class_id: filter.id
      };
      this.$router.push({ query });
      this.reload();
    },
    handleFilterChangeChip(id) {
      const query =
        id !== null ? { ...this.$route.query, article_class_id: id } : {};
      this.$router.replace({ query });
    },
    handleFilterChips() {
      if (this.articleClasses && this.articleClasses.length > 0) {
        this.articleClasses.forEach(item => {
          if (item.parameterName.startsWith("article_class")) {
            item.values.forEach(valueItem => {
              this.mergedFilterData.push({
                parameterName: item.parameterName,
                ...valueItem
              });
            });
          }
        });
      }
    }
  },
  async created() {
    await this.reload();
    this.handleFilterChips();
  },
  watch: {
    "$route.query.page": function() {
      this.reload();
    },
    "$route.query.article_class_id": function() {
      this.reload();
    }
  }
};
</script>
